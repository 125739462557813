<template>
  <main>
    <HeroPage
      title="Candidatos"
      description="Informações dos candidatos"
    ></HeroPage>
    <Breadcrumb :list="breadcrumbList"></Breadcrumb>
    <section>
      <div class="candidates-container">
        <div
          v-for="(diretorio, diretorio_id) in diretorios"
          :key="diretorio_id"
        >
          <div class="diretorio-header">
            <h3>{{ diretorio.nome }}</h3>
          </div>
          <div
            v-for="(cargo, cargo_id) in diretorio.chapas"
            :key="cargo_id"
            class="mb-10"
          >
            <div class="candidates-header">
              <h5>{{ "0" + cargo.numero + "/" + cargo.nome }}</h5>
            </div>
            <ul>
              <li
                v-for="(candidato, candidato_id) in cargo.candidatos"
                :key="candidato_id"
              >
                <div class="candidate-profile">
                  <img
                    v-if="candidato.foto"
                     :src="candidato.foto"
                     alt="avatar"
                     width="135"
                     height="145"
                  />
                  <div class="w-screen">
                    <div class="candidate-cargo">
                      <h1>{{ candidato.cargo }}</h1>
                    </div>
                    <div class="candidate-name">
                      <h1>{{ candidato.nome }}</h1>
                    </div>
                    <div v-if="hasCurriculum && candidato.cv">
                      <h5><a target="_blank" class="candidate-cv" :href="candidato.cv">
                        Minicurrículo</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul v-if="cargo.nomeformatado">
              <li>
                <h5 class="bg-amber-500 rounded p-2 text-white text-left">{{titleProposal}}</h5>
              </li>
              <li>
                <div class="candidate-profile">
                  <div class="w-screen">
                    <div class="candidate-cv" v-html="cargo.nomeformatado">

                    </div>
                  </div>
                </div>
              </li>
            </ul>

          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters  } from "vuex";
import HeroPage from "@/components/HeroPage.vue";
import Breadcrumb from "@/components/elements/Breadcrumb.vue";
import services from "@/services";
import _ from "lodash";
import { forEach } from "lodash/collection";

export default Vue.extend({
  name: "CandidatesDirectory",
  components: {
    HeroPage,
    Breadcrumb,
  },
  data() {
    return {
      hasCurriculum: false,
      titleProposal: 'Proposta',
      breadcrumbList: [],
      cargos: [],
      diretorios: [],
    };
  },
  computed: {
    ...mapGetters("eleicaonet", ["getParamHotsite"]),
  },
  methods: {
    ...mapActions(["changeLoading"]),
  },
  async mounted() {
    this.breadcrumbList = this.$route.meta.breadcrumb;
    let page = this.getParamHotsite.pages.candidatosDiretorio
    if (page && page.titleProposal) {
      this.titleProposal = page.titleProposal
    } else {
      this.titleProposal = 'Proposta'
    }
    if (page && page.hasCurriculum) {
      this.hasCurriculum = page.hasCurriculum
    } else {
      this.hasCurriculum = true
    }

    this.changeLoading(true);
    let response = await services.Eleicaonet.loadCandidates();

    let cargos = response.data;
    cargos = _.orderBy(cargos, ["diretorio", "ordem"]);

    cargos = _.filter(cargos, (cargo) => {
      return cargo.nome !== "BRANCO" && cargo.nome !== "NULO";
    });

    _.forEach(cargos, (cargo) => {
      cargo.candidatos = JSON.parse(cargo.participantes);
    });

    this.cargos = cargos;

    response = await services.Eleicaonet.loadDiretorios();
    let diretorios = response.data;
    diretorios = _.orderBy(diretorios, ["codigo"]);
    forEach(diretorios, (diretorio) => {
      diretorio.chapas = _.filter(cargos, (cargo) => {
        return cargo.diretorio === diretorio.codigo;
      });
      console.log("diretorio chapas", diretorio);
    });
    this.diretorios = diretorios;
    this.changeLoading(false);
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/sass/pages/candidates";
</style>
